import { ChevronRightIcon } from '@f8n/icons';
import { styled } from '@f8n-frontend/stitches';
import { useState } from 'react';
import { useMeasure } from 'react-use';
import { P, match } from 'ts-pattern';

import FauxInput from 'components/FauxInput';
import { MomentTag } from 'components/MomentTag';
import Badge from 'components/base/Badge';
import Box from 'components/base/Box';
import ChevronButton from 'components/base/ChevronButton';
import Divider from 'components/base/Divider';
import DropdownMenu from 'components/base/DropdownMenu';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';
import Tooltip from 'components/base/Tooltip';
import { PRODUCT_BRANDING } from 'copy/branding';

import { ApiMomentOverviewFragment } from 'gql/api/api-fragments.generated';
import { isMomentCuratedStore, isWorldCuratedStore } from 'utils/curated-store';
import { formatBasisPoints } from 'utils/formatters';

import { CuratedStore } from 'types/CuratedStore';
import { WorldOverview } from 'types/World';

import WorldTag from './WorldTag';

type CuratedStorePickerProps = {
  id: string;
  curatedStores: {
    worlds: WorldOverview[];
    moments: ApiMomentOverviewFragment[];
  };
  selectedCuratedStore: CuratedStore | null;
  selectCuratedStore: (curatedStore: CuratedStore) => void;
};

export default function CuratedStorePicker(props: CuratedStorePickerProps) {
  const { id, curatedStores, selectedCuratedStore, selectCuratedStore } = props;

  const [triggerInputRef, { width }] = useMeasure<HTMLButtonElement>();

  const [worldsExpanded, setWorldsExpanded] = useState(false);

  const renderWorldMenuItem = (world: WorldOverview) => {
    return (
      <CuratedStoreMenuItem
        key={world.id}
        type="button"
        onClick={() => {
          selectCuratedStore({
            type: 'WORLD',
            world,
          });
        }}
      >
        <Flex
          css={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <WorldTag type="list-item" world={world} />
          <Tooltip content="Curator fee">
            <Badge variant="primary" mono size={0}>
              {formatBasisPoints(world.takeRateInBasisPoints)}
            </Badge>
          </Tooltip>
        </Flex>
      </CuratedStoreMenuItem>
    );
  };

  const renderMomentMenuItem = (moment: ApiMomentOverviewFragment) => {
    return (
      <CuratedStoreMenuItem
        key={moment.id}
        type="button"
        onClick={() => {
          selectCuratedStore({
            type: 'MOMENT',
            moment,
          });
        }}
      >
        <Flex
          css={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <MomentTag type="list-item" moment={moment} world={moment.world} />
          <Tooltip content="Curator fee">
            <Badge variant="primary" mono size={0}>
              {formatBasisPoints(moment.takeRateInBasisPoints)}
            </Badge>
          </Tooltip>
        </Flex>
      </CuratedStoreMenuItem>
    );
  };

  const getContent = () => {
    if (curatedStores.moments.length > 0 && curatedStores.worlds.length > 0) {
      return (
        <>
          <CuratedStoreMenuHeading>
            Upcoming exhibitions
          </CuratedStoreMenuHeading>
          {curatedStores.moments.map(renderMomentMenuItem)}

          <Divider
            css={{
              marginY: '$2',
              marginX: '$3',
              width: 'auto',
            }}
          />

          <CuratedStoreMenuItem
            onClick={(ev) => {
              ev.preventDefault();
              setWorldsExpanded((isOpen) => !isOpen);
            }}
            css={{
              padding: 0,
              paddingRight: '$3',
            }}
          >
            <CuratedStoreMenuHeading>
              {PRODUCT_BRANDING.world.plural}{' '}
              <Badge variant="strong" mono size={0}>
                {curatedStores.worlds.length}
              </Badge>
            </CuratedStoreMenuHeading>

            <IconContainer isOpen={worldsExpanded}>
              <ChevronRightIcon />
            </IconContainer>
          </CuratedStoreMenuItem>
          {worldsExpanded && (
            <>{curatedStores.worlds.map(renderWorldMenuItem)}</>
          )}
        </>
      );
    } else if (curatedStores.worlds.length > 0) {
      return (
        <>
          <CuratedStoreMenuHeading>
            {PRODUCT_BRANDING.world.plural}{' '}
            <Badge variant="strong" mono size={0}>
              {curatedStores.worlds.length}
            </Badge>
          </CuratedStoreMenuHeading>
          {curatedStores.worlds.map(renderWorldMenuItem)}
        </>
      );
    } else {
      return (
        <>
          <CuratedStoreMenuHeading>
            Upcoming exhibitions
          </CuratedStoreMenuHeading>
          {curatedStores.moments.map(renderMomentMenuItem)}
        </>
      );
    }
  };

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger id={id} asChild>
        <ChevronButton
          ref={triggerInputRef}
          css={{ width: '100%', padding: 0, borderRadius: '$2' }}
          variant="base"
          type="button"
          icon
        >
          <FauxInput
            css={{
              background: '$black1',
              padding: '$2',
              paddingRight: '$4',
              paddingLeft: match(selectedCuratedStore)
                .with(
                  { type: 'WORLD', world: { imageUrl: P.string } },
                  () => '$2'
                )
                .with(
                  { type: 'MOMENT', moment: { posterUrl: P.string } },
                  () => '$2'
                )
                .otherwise(() => '$4'),
            }}
            variant={selectedCuratedStore ? 'normal' : 'placeholder'}
          >
            {match(selectedCuratedStore)
              .when(isWorldCuratedStore, ({ world }) => {
                return (
                  <Box>
                    <WorldTag type="static" world={world} showCuratorFee />
                  </Box>
                );
              })
              .when(isMomentCuratedStore, ({ moment }) => {
                return (
                  <Box>
                    <MomentTag
                      type="static"
                      world={moment.world}
                      moment={moment}
                      showCuratorFee
                    />
                  </Box>
                );
              })
              .otherwise(() => (
                <Box>Choose where to sell</Box>
              ))}
          </FauxInput>
        </ChevronButton>
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content minWidth={width} align="end" maxHeight={500}>
          {getContent()}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

const CuratedStoreMenuItem = styled(DropdownMenu.Item, {
  paddingX: '$3',
  paddingY: '$2',
});

const CuratedStoreMenuHeading = styled(Text, {
  paddingX: '$3',
  height: '46px',
  display: 'flex',
  alignItems: 'center',
  gap: '$1',
  flexGrow: 1,
});

CuratedStoreMenuHeading.defaultProps = {
  size: 1,
  weight: 'medium',
};

const IconContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  transition: 'transform $1 $ease',
  color: '$black50',
  variants: {
    isOpen: {
      true: {
        transform: 'rotate(90deg)',
      },
    },
  },
});
