import {
  UseQueryOptions,
  UseQueryResult,
  useQuery,
} from '@tanstack/react-query';
import { P, match } from 'ts-pattern';
import { Hex } from 'viem';
import type { Address } from 'viem';

import { signatureResponseSchema } from 'schemas/signature';
import { isHex } from 'utils/address';
import { isMomentCuratedStore } from 'utils/curated-store';
import { getPath } from 'utils/router';

import { CuratedStore } from 'types/CuratedStore';
import {
  GenerateSignatureOptions,
  SignatureListingType,
} from 'types/Signature';

async function generateSignature(
  options: GenerateSignatureOptions & { signal: AbortSignal | undefined }
) {
  const req = await fetch(getPath.api.generateSignature, {
    body: JSON.stringify(options),
    method: 'POST',
    /**
     * @see https://tanstack.com/query/v4/docs/react/guides/query-cancellation
     */
    signal: options.signal,
  });
  const res = await req.json();
  const parsed = signatureResponseSchema.parse(res);

  if (parsed.success) {
    return parsed.signature;
  } else {
    throw new Error(parsed.error);
  }
}

type GenerateMomentSignatureBaseVariables = {
  publicKey: Address;
  contractAddress: Address;
  txDeadlineUnix: number;
  selectedCuratedStore: CuratedStore | null;
};

export type GenerateMomentSignatureVariables =
  | (GenerateMomentSignatureBaseVariables & {
      listingType: Extract<SignatureListingType, 'NFT'>;
      tokenId: number;
    })
  | (GenerateMomentSignatureBaseVariables & {
      listingType: Extract<SignatureListingType, 'Collection' | 'Split'>;
    });

export type GenerateSignatureQueryResult = Pick<
  UseQueryResult<Hex, Error>,
  'isSuccess' | 'data'
>;

type GenerateMomentSignatureOptions = UseQueryOptions<Hex, Error>;

export function useGenerateMomentSignature(
  variables: GenerateMomentSignatureVariables,
  options: Pick<
    GenerateMomentSignatureOptions,
    'refetchOnWindowFocus' | 'enabled'
  > = {}
) {
  /**
   * extract the enabled option, and combine it
   * with the custom enabled guard below
   */
  const { enabled, ...useQueryOptions } = options;

  const isUseQueryEnabled = enabled ?? true;

  return useQuery({
    ...useQueryOptions,
    queryKey: ['moment', 'generate-signature', variables],
    queryFn: async ({ signal }) =>
      getMomentSignature({
        ...variables,
        /**
         * @see https://tanstack.com/query/v4/docs/react/guides/query-cancellation
         */
        signal,
      }),
    enabled:
      isMomentCuratedStore(variables.selectedCuratedStore) && isUseQueryEnabled,
  });
}

export type GenerateSignaturesQueryResult = Pick<
  UseQueryResult<Hex[], Error>,
  'isSuccess' | 'data'
>;

type GenerateMomentSignaturesOptions = UseQueryOptions<Hex[], Error>;

export type GenerateMomentSignaturesVariables =
  GenerateMomentSignatureBaseVariables & {
    listingType: Extract<SignatureListingType, 'NFT'>;
    tokenIds: number[];
  };

export function useGenerateMomentSignatures(
  variables: GenerateMomentSignaturesVariables,
  options: Pick<
    GenerateMomentSignaturesOptions,
    'refetchOnWindowFocus' | 'refetchOnMount' | 'refetchOnReconnect' | 'enabled'
  > = {}
) {
  /**
   * extract the enabled option, and combine it
   * with the custom enabled guard below
   */
  const { enabled, ...useQueryOptions } = options;

  const isUseQueryEnabled = enabled ?? true;

  return useQuery({
    queryKey: ['moment', 'generate-signatures', variables],
    queryFn: async ({ signal }) => {
      const { tokenIds, ...options } = variables;
      return await Promise.all(
        tokenIds.map((tokenId) =>
          getMomentSignature({
            ...options,
            tokenId,
            /**
             * @see https://tanstack.com/query/v4/docs/react/guides/query-cancellation
             */
            signal,
          })
        )
      );
    },
    enabled:
      isMomentCuratedStore(variables.selectedCuratedStore) && isUseQueryEnabled,
    ...useQueryOptions,
  });
}

export async function getMomentSignature(
  options: GenerateMomentSignatureVariables & {
    signal: AbortSignal | undefined;
  }
) {
  const {
    publicKey,
    txDeadlineUnix,
    contractAddress,
    selectedCuratedStore,
    listingType,
    signal,
  } = options;

  if (isMomentCuratedStore(selectedCuratedStore)) {
    const moment = selectedCuratedStore.moment;
    return await generateSignature({
      chainId: moment.world.chainId,
      worldId: moment.world.id,
      publicKey,
      contractAddress,
      /**
       * when a tokenId is pased in, we use that, otherwise we use 0
       * passing a value of 0 assumes it is a collection or split
       */
      tokenId: mapSignatureVariablesToTokenId(options),
      takeRateInBasisPoints: moment.takeRateInBasisPoints,
      momentId: moment.id,
      expiration: txDeadlineUnix,
      listingType,
      signal,
    });
  } else {
    /**
     * this should never happen, but we need to throw an error
     */
    throw new Error('Unsupported curated store type');
  }
}

const mapSignatureVariablesToTokenId = (
  variables: GenerateMomentSignatureVariables
): number => {
  return match(variables)
    .with({ tokenId: P.number }, (data) => data.tokenId)
    .otherwise(() => 0);
};

export const getMomentSignatureData = (
  query: GenerateSignatureQueryResult
): Hex => {
  if (query.isSuccess && isHex(query.data, { strict: true })) {
    return query.data;
  } else {
    return '0x';
  }
};
