import { z } from 'zod';

export const signatureListingType = z.enum(['NFT', 'Collection', 'Split']);
export const signatureApprovalType = z.enum(['SellerAllowlist', 'Signature']);

import { chainIdSchema } from 'schemas/url/chain';

import { addressValueSchema, hexValueSchema } from './shared';

export const signatureResponseSchema = z.union([
  z.object({
    success: z.literal(true),
    signature: hexValueSchema,
  }),
  z.object({
    success: z.literal(false),
    error: z.string(),
  }),
]);

const uintSchema = z.number().positive().safe();

export const signatureOptionsSchema = z.object({
  chainId: chainIdSchema,
  worldId: uintSchema,
  momentId: uintSchema,
  tokenId: z.number().nonnegative(),
  expiration: uintSchema,
  takeRateInBasisPoints: z.number().nonnegative(),
  publicKey: addressValueSchema,
  contractAddress: addressValueSchema,
  listingType: signatureListingType,
});
